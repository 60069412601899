// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-for-clinicians-js": () => import("./../../../src/pages/for-clinicians.js" /* webpackChunkName: "component---src-pages-for-clinicians-js" */),
  "component---src-pages-for-workplaces-js": () => import("./../../../src/pages/for-workplaces.js" /* webpackChunkName: "component---src-pages-for-workplaces-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-articles-cognitive-reappraisal-js": () => import("./../../../src/pages/library/articles/cognitive-reappraisal.js" /* webpackChunkName: "component---src-pages-library-articles-cognitive-reappraisal-js" */),
  "component---src-pages-library-articles-emotions-feelings-and-moods-js": () => import("./../../../src/pages/library/articles/emotions-feelings-and-moods.js" /* webpackChunkName: "component---src-pages-library-articles-emotions-feelings-and-moods-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-library-podcasts-burnout-minding-your-mind-js": () => import("./../../../src/pages/library/podcasts/burnout-minding-your-mind.js" /* webpackChunkName: "component---src-pages-library-podcasts-burnout-minding-your-mind-js" */),
  "component---src-pages-links-fitness-js": () => import("./../../../src/pages/links/fitness.js" /* webpackChunkName: "component---src-pages-links-fitness-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-our-origins-js": () => import("./../../../src/pages/our-origins.js" /* webpackChunkName: "component---src-pages-our-origins-js" */),
  "component---src-pages-project-synergy-js": () => import("./../../../src/pages/project-synergy.js" /* webpackChunkName: "component---src-pages-project-synergy-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-pages-your-clinical-professionals-js": () => import("./../../../src/pages/your-clinical-professionals.js" /* webpackChunkName: "component---src-pages-your-clinical-professionals-js" */),
  "component---src-pages-your-members-js": () => import("./../../../src/pages/your-members.js" /* webpackChunkName: "component---src-pages-your-members-js" */),
  "component---src-pages-your-staff-js": () => import("./../../../src/pages/your-staff.js" /* webpackChunkName: "component---src-pages-your-staff-js" */),
  "component---src-pages-your-students-js": () => import("./../../../src/pages/your-students.js" /* webpackChunkName: "component---src-pages-your-students-js" */)
}

